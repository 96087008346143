@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 100;
	src: local('Helvetica Neue'), local('Helvetica'), local('Arial'),
		local('sans-serif'), url('../../assets/Lato100.woff2') format('woff2');
}

@font-face {
	font-family: 'Lato';
	font-style: italic;
	font-weight: 400;
	src: local('Helvetica Neue'), local('Helvetica'), local('Arial'),
		local('sans-serif'), url('../../assets/Lato400Italic.woff2') format('woff2');
}

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	src: local('Helvetica Neue'), local('Helvetica'), local('Arial'),
		local('sans-serif'), url('../../assets/Lato400.woff2') format('woff2');
}

@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	src: local('Helvetica Neue'), local('Helvetica'), local('Arial'),
		local('sans-serif'), url('../../assets/Lato700.woff2') format('woff2');
}
