@import './common.scss';

html {
	background-color: var(--background);
}

body {
	margin: 0px;
}

button {
	user-select: none;
}

h1,
h2,
h3 {
	font-family: 'Montserrat Alternates';
}

:focus-visible {
	outline: none;
}

* {
	scrollbar-color: var(--text-placeholder);

	// 12px wide

	&::-webkit-scrollbar {
		width: $scrollbar-width;
	}

	&::-webkit-scrollbar-thumb {
		height: 56px;
		border: 4px solid transparent;
		border-radius: 8px;
		background-clip: content-box;
		background-color: var(--text-placeholder);
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: var(--text-disabled);
	}
}

/*@include screenMobile {
	#root {
		width: 100%;
	}

	.fixed-wide {
		width: 100vw;
	}
}*/

#root {
	width: $fixed-width;
	font-family: 'Lato';
	font-weight: 400;
	color: var(--text);
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	// hide "absolute" overflow
	overflow: hidden;
	position: relative;
}

main {
	padding: 0px 5%;
	margin: 20px 0px;
	flex: none;

	&.error {
		pre {
			white-space: break-spaces;
		}
	}
}

input {
	font-family: inherit;

	&::placeholder {
		color: var(--text-placeholder);
	}
}

a {
	text-decoration: inherit;
	color: inherit;
}
