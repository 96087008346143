@import './common.scss';

.main {
	display: flex;
	margin: 0;
	padding: 0;
	padding-left: 300px;
	flex-direction: column;
	display: flex;

	> section {
		margin: 0;
		display: flex;
		flex-direction: column;
		width: 100%;
		height: auto;
		padding: 15px 30px;
		box-sizing: border-box;

		.ThemeInput,
		.ThemeInputBar {
			width: 501px;

			@media only screen and (max-width: 861px) {
				width: auto;
			}
		}

		.ThemeSelect {
			margin-right: auto;
		}

		> div {
			display: flex;
			flex-direction: column;
			margin-bottom: 10px;
			width: 100%;

			> p {
				display: block;
				margin-bottom: 5px;
			}
		}
	}

	> .menu {
		height: 100%;
		position: fixed;
		flex-direction: column;
		overflow-y: auto;
		display: flex;
		background-color: var(--background);
		left: 0;
		top: 0;
		margin-top: $nav-height-border;
		border-right: 1px solid var(--border);
		width: 300px;
		height: calc(100vh - $nav-height-border);
		box-sizing: border-box;
		transform: translateX(0);
		z-index: 1;
	}

	@include screenMobile {
		--menu-height: 76px;
		padding-left: 0px;
		padding-top: var(--menu-height);
		flex-direction: column;

		> .menu {
			height: var(--menu-height);
			width: $fixed-width;
			flex-direction: row;
			overflow: hidden;
			border-right: none;
			border-bottom: var(--border) 1px solid;

			> .menuList {
				width: 100%;
				display: flex;
				flex-direction: row;
				overflow-y: hidden;
				padding: 5px;
				box-sizing: border-box;

				> .entry {
					display: flex;
					flex-direction: column;
					justify-content: center;
					width: 100%;
					height: 100%;
					box-sizing: border-box;
					border-radius: 5px;

					> .icon {
						margin-right: 0;
					}
				}
			}
		}
	}
}
